import classes from "./App.module.css";

export const App = () => {
  const isProduction = import.meta.env.VITE_ENV === "production";

	return (
		<div className={classes.container}>
			<h1 className={classes.title}>Zenith SpA</h1>
			<h2>Development Services</h2>
			<p className={classes.description}>
				Your partner in turning ideas into reality. We offer top-notch
				development services to grow your business and bring your vision to
				life.
			</p>
			<h5>Tomás Hernández - CEO @ Zenith SpA</h5>
			<a href="mailto:contact@zenith.spa" className={classes.ctaBtn}>
				Get in Touch
			</a>
      {!isProduction ? (
        <h6>{import.meta.env.VITE_BRANCH} - {import.meta.env.VITE_ENV}</h6>
      ) : null}
		</div>
	);
};
